import React from 'react'
import * as ROUTER from 'react-router-dom'
import styled from 'styled-components'
import * as BS from 'react-bootstrap'

import * as HOOK from '../../../hooks'
import * as IMG from '../../../images'
import * as COM from '../../../components'
import { ELE, STC } from '../../../components'

const _fontsize = '1.5rem'

const PeriodicalDetailPage = () => {
    // hook
    const { periodicalId } = ROUTER.useParams()

    const [periodicals] = HOOK.usePeriodicals()

    const periodical = React.useMemo(
        () => periodicals?.find((v) => v.id === periodicalId),
        [periodicals, periodicalId]
    )

    function PeriodicalContent(htmlString) {
        return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
    }

    return (
        <STC.MainBox>
            {periodical && (
                <>
                    <COM.Title1 title={periodical.label} tag={periodical.author} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: "100%" }}>
                        <ELE.Image width={15} ratio={358 / 206} src={IMG.temp4} onClick={() => window.open("https://law.moj.gov.tw/")} />
                        <ELE.Image width={15} ratio={358 / 206} src={IMG.back_page_icon} onClick={() => history.back()} />
                    </div>
                    <STC.MainBox style={{ marginTop: 0 }}>
                        <BS.Row>
                            <BS.Col
                                xs={{ offset: 6, span: 5 }}
                                sm={{ offset: 6, span: 4 }}
                                md={{ offset: 7, span: 3 }}
                                xl={{ offset: 8, span: 2 }}
                            >
                            </BS.Col>
                        </BS.Row>
                        <ELE.View width={100} ratio={2} bg={'#ccc'}>
                            <Box>
                                <ELE.Text>
                                    {PeriodicalContent(periodical.detail)}
                                </ELE.Text>
                            </Box>
                        </ELE.View>
                    </STC.MainBox>
                </>
            )}
        </STC.MainBox>
    )
}

export default PeriodicalDetailPage

// styled-components
const Box = styled.div`
  width: 100%;
  height: 100%;

  padding: calc(${_fontsize} * 2);
  font-size: ${_fontsize};
  overflow-y: auto;
`
