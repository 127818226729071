import React from 'react'
import styled from 'styled-components'
import * as BS from 'react-bootstrap'

import * as HOOK from '../../hooks'
import * as COM from '../../components'
import { ELE, STC } from '../../components'

const _ratio = 672 / 966

const AboutPage = () => {
  // hook
  const [about] = HOOK.useAbout()
  const { introduction, structure, service } = about

  return (
    <>
      <STC.MainBox>
        <COM.Title1 title={introduction.label} />

        <STC.MainBox>
          <BS.Row>
            <BS.Col xs={12} lg={6} xl={5}>
              <STC.MainBox>
                {introduction?.detail.map((v) => (
                  <React.Fragment key={v}>
                    <StyleText>{v}</StyleText>
                    <br />
                    <br />
                  </React.Fragment>
                ))}
              </STC.MainBox>
            </BS.Col>

            <BS.Col
              xs={12}
              lg={{ offset: 1, span: 5 }}
              xl={{ offset: 2, span: 4 }}
            >
              <View5 width={'100%'} ratio={_ratio}>
                <Image2 src={introduction.img[1]} />
              </View5>
            </BS.Col>
          </BS.Row>
        </STC.MainBox>
      </STC.MainBox>

      <STC.MainBox>
        <COM.Title1 title={structure.label} />

        <MainBox>
          {structure?.detail.map((v, i) => {
            return (
              <ELE.View key={`structure${i}`} width={25} ratio={2 / 3} bottom>
                {i === 1 ? (
                  <View2 width={100} circle>
                    <StyleText3 title={v[0]} />
                  </View2>
                ) : (
                  <View2 width={80} bg={'#111'} circle>
                    <StyleText6 title={v[0]} />
                  </View2>
                )}
                <StyleText4 title={v[1]} />
                <StyleText5 title={v[2]} />
              </ELE.View>
            )
          })}
        </MainBox>
      </STC.MainBox>

      <STC.MainBox>
        <COM.Title1 title={service.label} />

        <STC.MainBox>
          <BS.Row>
            {service?.detail.map((v, i) => {
              const order = i % 2

              return (
                <SetCol
                  key={v.label}
                  xs={{ offset: 1, span: 10 }}
                  sm={{ offset: 2, span: 8 }}
                  md={{ offset: 1, span: 5 }}
                  lg={{ offset: order ? 2 : 1, span: 4 }}
                  xl={{ offset: order ? 2 : 1, span: 4 }}
                  {...v}
                />
              )
            })}
          </BS.Row>
        </STC.MainBox>
      </STC.MainBox>
    </>
  )
}

export default AboutPage

// styled-components
const MainBox = styled(STC.MainBox)`
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`

const StyleText = styled(ELE.Text)`
  font-size: 1.5rem;
`

const StyleText3 = styled(ELE.Text)`
  font-size: 2.5vw;
`

const StyleText4 = styled(ELE.Text)`
  font-size: 2vw;
  font-weight: 700;
  color: #51bad1;
`

const StyleText5 = styled(ELE.Text)`
  font-size: 1.5vw;
`

const StyleText6 = styled(ELE.Text)`
  font-size: 3vw;
  font-weight: 900;
  color: #fff;
`

const View2 = styled(ELE.View)`
  border: solid 5px #222;
`

const View5 = styled(ELE.View)``

const Image1 = styled.img`
  width: 97%;
  height: 77%;
  position: absolute;
  object-fit: cover;
`

const Image2 = styled(Image1)`
  top: 0;
  right: 0;
`

// child components
const SetCol = ({ icon, label, detail, ...props }) => {
  return (
    <BoxCol {...props}>
      <STC.MainBox>
        <View1 width={100} ratio={_ratio}>
          <ELE.View width={45} height={45}>
            <ELE.Image width={100} ratio={1} src={icon} />
            <StyleText1 title={label} />
          </ELE.View>

          <ELE.View width={100} height={55}>
            <StyleText2 title={detail} />
          </ELE.View>
        </View1>
      </STC.MainBox>
    </BoxCol>
  )
}

const BoxCol = styled(BS.Col)``

const View1 = styled(ELE.View)`
  border: solid 3px #777;
`

const StyleText1 = styled(ELE.Text)`
  font-size: 2.5vw;
`

const StyleText2 = styled(ELE.Text)`
  font-size: 1.3rem;
  padding: 1rem;
  height: 100%;
`
