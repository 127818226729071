import React from 'react'
import * as ROUTER from 'react-router-dom'
import styled from 'styled-components'
import * as BS from 'react-bootstrap'

import * as HOOK from '../../../hooks'
import * as IMG from '../../../images'
import * as COM from '../../../components'
import { ELE, STC } from '../../../components'

const _fontsize = '1.5rem'

const NewsDetailPage = () => {
    // hook
    const { newsId } = ROUTER.useParams()

    const [news] = HOOK.useNews()

    const oneNews = React.useMemo(
        () => news?.find((v) => v.id === newsId),
        [news, newsId]
    )

    function NewsContent(htmlString) {
        return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
    }

    return (
        <STC.MainBox>
            <div></div>

            {oneNews && (
                <>
                    <COM.Title1 title={oneNews.label} tag={oneNews.author} back_page={IMG.back_page_icon} />

                    <STC.MainBox>
                        <BS.Row>
                            <BS.Col
                                xs={{ offset: 6, span: 5 }}
                                sm={{ offset: 6, span: 4 }}
                                md={{ offset: 7, span: 3 }}
                                xl={{ offset: 8, span: 2 }}
                            >
                            </BS.Col>
                        </BS.Row>

                        <ELE.View width={100} ratio={2} bg={'#ccc'}>
                            <Box>
                                <ELE.Text>
                                    {NewsContent(oneNews.detail)}
                                </ELE.Text>
                            </Box>
                        </ELE.View>
                    </STC.MainBox>
                </>
            )}
        </STC.MainBox>
    )
}

export default NewsDetailPage

// styled-components
const Box = styled.div`
  width: 100%;
  height: 100%;

  padding: calc(${_fontsize} * 2);
  font-size: ${_fontsize};
  overflow-y: auto;
`
