import React from 'react'
import styled from 'styled-components'
import * as BS from 'react-bootstrap'
import * as ROUTER from 'react-router-dom'
import * as HOOK from '../../../hooks'
import { ELE, STC } from '../../../components'
import { Card } from 'antd';
import dayjs from "dayjs";

const StyledCard = styled(Card)`
  width: 300px;
  height: 35%;
  margin: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 10px #ccc;
  }
`;


const NewsPage = () => {
    // hook
    const history = ROUTER.useHistory()
    const [news] = HOOK.useNews()

    const newArr = news.map((v) => ({
        onClick: () => history.push(`/news/${v.id}`),
        ...v,
    }))

    return (
        <STC.MainBox>
            <ELE.View width={100} ratio={1} bg={'#657C89'}>
                <BoxContainer>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', height: '100%', alignItems: 'center' }}>
                        {newArr.sort((a, b) => b.id - a.id).slice(0, 6).map((v, index) => {
                            return (
                                <StyledCard 
                                    key={index} 
                                    title={v.label} 
                                    extra={dayjs(v.date).format("YYYY-MM-DD")} 
                                    onClick={()=> history.push(`/news/${v.id}`)}
                                >
                                    <p>{v.preview}</p>
                                </StyledCard>
                            )
                        })}
                    </div>
                </BoxContainer>
            </ELE.View>
        </STC.MainBox>
    )
}

export default NewsPage

// styled-components
const BoxContainer = styled(BS.Container)`
  height: 90%;
  overflow-y: auto;
`