import React from 'react'
import styled from 'styled-components'
import * as BS from 'react-bootstrap'

import * as ELE from './@elements'
import * as IMG from '../images'
import * as HOOK from '../hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    const [footer] = HOOK.useFooter()
    const { taoyuan, taipei, title } = footer;
    return (
        <Container>
            <BoxRow>
                <BS.Col
                    xs={12}
                    md={{ offset: 2, span: 10 }}
                    lg={{ offset: 1, span: 11 }}
                    xl={{ offset: 1, span: 11 }}
                >
                    <BS.Row>
                        <BoxCol
                            xs={12}
                            lg={5}
                            xl={4}
                        // lg={4}
                        // xl={{ offset: 1, span: 3 }}
                        >
                            <TitleRow>{title}</TitleRow>
                            <ELE.Image width={'40%'} ratio={3} src={IMG.fb_icon} styled={{ "padding-left": "3rem" }} onClick={() => location.href = "https://www.facebook.com/%E6%B5%A9%E5%AE%87%E6%B3%95%E5%BE%8B%E4%BA%8B%E5%8B%99%E6%89%80-106262848446780/?ref=pages_you_manage"} />
                        </BoxCol>
                        <BoxCol xs={12} lg={5} xl={4}>
                            <Box1>
                                <BoxRow1>
                                    <BS.Col className='d-none d-lg-flex'>
                                        <ELE.Text title={`${taoyuan.site} 電話${taoyuan.phone} 傳真${taoyuan.tax}`} />
                                    </BS.Col>
                                </BoxRow1>

                                <BoxRow2 xs={1}>
                                    <SetCol1
                                        key={2}
                                        label={"email"}
                                        detail={taoyuan.email}
                                    />
                                    <SetCol1
                                        key={3}
                                        label={"地址"}
                                        detail={
                                            <>
                                                {taoyuan.address}
                                                <FontAwesomeIcon icon={faMapMarkedAlt} style={{ color: "white", marginLeft: 3 }} />
                                            </>
                                        }
                                        onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent("浩宇法律事務所")}&query_place_id=24.99088,121.29659`, '_blank')}
                                        style={{ cursor: "pointer" }}
                                    />
                                </BoxRow2>
                            </Box1>
                        </BoxCol>

                        <BoxCol
                            xs={12}
                            lg={5}
                            xl={4}
                        // lg={4}
                        // xl={{ offset: 1, span: 3 }}
                        >
                            <Box1>
                                <BoxRow1>
                                    <BS.Col className='d-none d-lg-flex'>
                                        <ELE.Text title={`${taipei.site} 電話${taipei.phone} 傳真${taipei.tax}`} />
                                    </BS.Col>
                                </BoxRow1>

                                <BoxRow2 xs={1}>
                                    <SetCol1
                                        key={2}
                                        label={"email"}
                                        detail={taipei.email}
                                    />
                                    <SetCol1
                                        key={3}
                                        label={"地址"}
                                        detail={
                                            <>
                                                {taipei.address}
                                                <FontAwesomeIcon icon={faMapMarkedAlt} style={{ color: "white", marginLeft: 3 }} />
                                            </>
                                        }
                                        onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent("浩宇台北所")}&query_place_id=25.046151859560158,121.51165184417947`, '_blank')}
                                        style={{ cursor: "pointer" }}
                                    />
                                </BoxRow2>
                            </Box1>
                        </BoxCol>


                    </BS.Row>
                </BS.Col>
            </BoxRow>
        </Container>
    )
}

export default Footer

// styled-components
const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 1rem;
  background-color: #212121;
  color: #fff;
`

const TitleRow = styled(BS.Row)`
  font-size: 1.3rem;
  padding: 0.01rem;
`

const BoxRow = styled(BS.Row)`
  font-size: 1rem;
`

const BoxCol = styled(BS.Col)`
  padding: 0rem;
  /* display: flex;
  justify-content: center;
  align-items: center; */
`

const Box1 = styled.div`
  /* padding-left: 4rem; */
`

const BoxRow1 = styled(BS.Row)`
  padding: 0.01rem;
`

const BoxRow2 = styled(BS.Row)`
  padding: 0.5rem;
  padding-left: 4rem;
`

const SetCol1 = ({ label, detail, ...props }) => {
    return (
        <BoxCol3 {...props}>
            <ELE.Text title={label} />
            &nbsp; &nbsp;
            <ELE.Text title={detail} />
        </BoxCol3>
    )
}

const BoxCol3 = styled(BS.Col)`
  display: flex;
  flex-direction: row;
`
