import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import * as ELE from './@elements'

const _size = `calc(1em * 8 / 5)`

const Title1 = ({ title, back_page, tag }) => {
    const [title1, title2] = [title.slice(0, 1), title.slice(1)]
    const showBackPage = () => back_page ? <ELE.Image width={20} ratio={358 / 206} src={back_page} onClick={() => history.back()} /> : ""
    const showTag = () => tag ? (
        <div style={{ display: 'flex', alignItems: 'center', width: "10%" }}>
            <ELE.Text title={tag} style={{ fontSize: "large", width: "100%" }} />
        </div>
    ) : ""
    // styled-components
    const Container = styled.div`
    width: 100%;
    height: auto;
    border-bottom: solid 2px #111;
    font-size: 3rem;
    font-weight: 350;
    padding-bottom: calc(3rem / 5);
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 500px) {
        font-size: 2rem
    }

    @media screen and (max-width: 400px) {
        font-size: 1.5rem
    }
  `
    return (
        <Container>
            <ELE.View width={_size} height={_size} bg={'#657C89'} circle>
                <StyleText1 title={title1} />
            </ELE.View>

            <ELE.View width={`calc(100% - ${_size})`} height={_size}>
                <StyleText2 title={title2} />
            </ELE.View>
            {/* raw: 358 / 106 */}
            {showBackPage()}
            {showTag()}
        </Container>
    )
}

export default Title1

Title1.propTypes = {
    title: PropTypes.string,
}



const StyleText = styled(ELE.Text)`
  position: absolute;
  bottom: 0;
`

const StyleText1 = styled(StyleText)`
  right: 0;
  color: #fff;
`

const StyleText2 = styled(StyleText)`
  width: 100%;
`
