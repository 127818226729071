import React from 'react'

import * as COM from '../../components'
import { STC } from '../../components'
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const markers = [
    { lat: 24.99088, lng: 121.29659, label: "浩宇法律事務所" },
    { lat: 24.9918, lng: 121.29112, color: "blue", label: "全方位停車場中平站 ALL-WAY Parking CP Station" },
    { lat: 24.99382, lng: 121.29405, color: "blue", label: "Times 桃園仁愛路停車場" },
    { lat: 24.99167, lng: 121.30043, color: "blue", label: "Times 桃園市政府前停車場" },
    { lat: 24.98733, lng: 121.29188, color: "blue", label: "宏昌公有停車場" },
    { lat: 24.99257, lng: 121.29143, color: "blue", label: "松壽停車場" },
    { lat: 24.99245, lng: 121.2909, color: "blue", label: "全方位停車場中平二站" },
];

const containerStyle = {
    width: "100%",
    height: "500px",
};

const ParkingPage = () => {
    return (
        <STC.MainBox>
            <COM.Title1 title={"停車資訊"} />

            <STC.MainBox>
                <LoadScript googleMapsApiKey="AIzaSyC95iFoOfjyja1TwYiU4wmB-262-wlQaik">
                    <GoogleMap 
                        mapContainerStyle={containerStyle} 
                        center={markers[0]} 
                        zoom={16} 
                        options={{ fullscreenControl: true }}
                    >
                        {markers.map((marker, index) => (
                            <Marker 
                                key={index} 
                                position={{ lat: marker.lat, lng: marker.lng }} 
                                label={marker.label} 
                                icon={marker.color && `http://maps.google.com/mapfiles/ms/icons/${marker.color}-dot.png`}
                                onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(marker.label)}&query_place_id=${marker.lat},${marker.lng}`, '_blank')}
                            />
                        ))}
                    </GoogleMap>
                </LoadScript>
            </STC.MainBox>
        </STC.MainBox>
    )
}

export default ParkingPage